body {
    margin: auto;
    width: 70%;
    padding: 10px;
    font-family: 'Noto Sans KR', sans-serif;
    
}
h1 {
    font-size: 60px;
    margin: 0;
    padding: 30px 0 8px;
    position: relative;
    font-weight: bold;
}

button {
    display: inline-block;
    outline: 0;
    appearance: none;
    padding: 0px 12px;
    border: 0px solid transparent;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    height: 36px;
    transition: all 150ms ease-in-out 0s;
    :hover {
        color: rgb(255, 255, 255);
        background-color: rgb(17, 97, 73);
        box-shadow: rgb(19 170 82 / 40%) 0px 2px 3px, rgb(195 231 202) 0px 0px 0px 3px;
    }

}

button.login {
    background-color: rgb(9, 128, 76);
    box-shadow: rgb(19 170 82 / 40%) 0px 2px 3px;
    color: rgb(255, 255, 255);
}

button.logout {
    background-color: rgb(255, 255, 0);
    box-shadow: rgb(255,255, 0 / 40%) 0px 2px 3px;
    color: rgb(0, 0, 0);
}